import React, { Component } from 'react'
import { Link } from "../../plugins/gatsby-plugin-atollon"

import { Logo, ThemeToggle } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = () => {
    this.setState({
      scrolled: window.scrollY > 1,
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled } = this.state
    let { theme } = this.props

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) headerClass += ' header--open'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Harris+Wood' className='header__logo' {...props}>
              <Logo color={theme === 'Dark' ? '#FFFFFF' : '#000000' } />
            </Link>
            <nav className='header__nav'>
              <ul>
                <li><Link to='/buy/' {...props}>Listings</Link></li>
                <li><Link to='/appraisals/' {...props}>Appraisals</Link></li>
                <li><Link to='/about/' {...props}>About</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
                <li>
                  <button 
                    type='button'
                    onClick={this.props.toggleSwitch}
                  >
                    <ThemeToggle color={theme === 'Dark' ? '#FFFFFF' : '#000000' } />
                    <span>{theme} Mode</span>
                  </button>
                </li>
                <li>
                  <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                    <span className='lines'></span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li>
                  <Link to='/buy/' {...props}>Listings</Link>
                  <ul>
                    <li><Link to='/buy/' {...props}>For Sale</Link></li>
                    <li><Link to='/lease/' {...props}>For Lease</Link></li>
                    <li><Link to='/sold/' {...props}>Sold</Link></li>
                    <li><Link to='/appraisals/' {...props}>Appraisals</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to='/about/' {...props}>About</Link>
                  <ul>
                    <li><Link to='/about/' {...props}>About Us</Link></li>
                    <li><Link to='/agents/' {...props}>Agents</Link></li>
                  </ul>
                </li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
              </ul>
            </nav>
            <ul className='off-canvas__address'>
              <li className='address'>
                <Link to='tel:+61355612228'>03 5561 2228</Link><br />
                <Link to='mailto:info@harriswood.com.au'>info@harriswood.com.au</Link>
              </li>
              <li>
                <Link to='/contact'>192 Koroit Street<br />Warrnambool Victoria 3280</Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    )
  }
}

export default Header
